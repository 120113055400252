import Vue from 'vue';
import Cookie from 'js-cookie';

const initialUser = {
  id: Cookie.get('id'),
	profile: Cookie.get('profile') || localStorage.getItem('profile'),
	token: Cookie.get('token'),
	email: Cookie.get('email'),
	nome: Cookie.get('nome'),
	unidade: Cookie.get('unidade'),
	empreendimento_id: Cookie.get('empreendimento_id'),
	empreendimento_logo: Cookie.get('empreendimento_logo'),
	empreendimento_ico: Cookie.get('empreendimento_ico'),
	construtora_id: Cookie.get('construtora_id'),
	construtora_logo: Cookie.get('construtora_logo'),
	primeiro_acesso: Cookie.get('primeiro_acesso'),
	permissoes: Cookie.get('permissoes'),
	is_construtora: Cookie.get('is_construtora'),
	assistencia_tecnica_construtora_filtro: Cookie.get('assistencia_tecnica_construtora_filtro'),
	integracao_xrm: Cookie.get('integracao_xrm'),
	construtora_created_at: Cookie.get('construtora_created_at'),
	empreendimento_created_at: Cookie.get('empreendimento_created_at')
};

export const user = Vue.observable(initialUser);

export const removeUserInfo = () => {
	Object.keys(user).forEach((key) => {
		user[key] = '';
		Cookie.remove(key);
	});
};

export default user;